<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <div class="tabs">


            <div class="tab">
              <input type="radio" id="rd1" name="rd" />
              <label class="tab-label" for="rd1">
                <p>Rowerowa przejażdżka</p>
              </label>
              <div class="tab-content">
                Wraz z Towarzystwem Rowerowym im. Bł. Edmunda Bojanowskiego z Piasków odkryjecie nieznane zakątki terenów wokół Świętej Góry. Wysoki poziom endorfin gwarantowany! Koniecznie zabierz swój rower i ruszaj w przygodę!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd2" name="rd" />
              <label class="tab-label" for="rd2">
                <p>Warsztaty z gitarą</p>
              </label>
              <div class="tab-content">
                Masz gitarę i grasz na niej? Zabierz ze sobą, bo te warsztaty są właśnie dla Ciebie! Co Cię czeka? Podstawy i planowanie improwizacji, tonacje durowe, molowe, skale muzyczne, pentatonika, występowanie akordów w tonacjach oraz dobór akordów do akompaniamentu, a także budowanie fraz oraz zdań muzycznych.
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd3" name="rd" />
              <label class="tab-label" for="rd3">
                <p>Warsztaty plastyczne</p>
              </label>
              <div class="tab-content">
                To warsztaty dla osób spełniających się artystycznie. Z nami na pewno stworzysz coś wielkiego! Co robimy na tych warsztatach? Malujemy, rysujemy, tworzymy, a świat czynimy jeszcze piękniejszym!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd4" name="rd" />
              <label class="tab-label" for="rd4">
                <p>Taneczne uwielbienie</p>
              </label>
              <div class="tab-content">
                „Tańcem chwalmy Boga...” Kto śpiewa ten dwa razy się modli, ten kto tańczy? Uuuuuuu, strach pomyśleć! Na tych warsztatach przez taniec i modlitwę uwielbienia możecie liczyć na świetną zabawę oraz integrację, a to wszystko wielbiąc Boga! Poziom endorfin eksploduje!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd5" name="rd" />
              <label class="tab-label" for="rd5">
                <p>Warsztaty teatralne</p>
              </label>
              <div class="tab-content">
                Tajniki sztuki aktorskiej nie będą miały przed nami żadnych tajemnic. Postaramy się przygotować małą formę teatralną, którą zaprezentujemy pozostałym. Jeśli chcesz miło spędzić czas, lubisz pracę w grupie i odgrywanie różnych ról, masz wyobraźnię i lubisz bujać w obłokach to warsztaty teatralne są dla Ciebie!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd6" name="rd" />
              <label class="tab-label" for="rd6">
                <p>Warsztaty rękodzielnicze</p>
              </label>
              <div class="tab-content">
                Warsztaty poprowadzą dobrze nam znane z poprzednich edycji Paradiso „Biskupianki w podróży”, które krzewią kulturę grupy etnograficznej z regionu gostyńskiego. Z ich bogatą wiedzą i doświadczeniem zmalujemy coś fantrastycznego!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd7" name="rd" />
              <label class="tab-label" for="rd7">
                <p>Warsztaty fitness</p>
              </label>
              <div class="tab-content">
                Ruch to zdrowie, więc pakujcie strój sportowy i przybywajcie! Ogromna dawka ruchu i muzyki gwarantowana.
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd8" name="rd" />
              <label class="tab-label" for="rd8">
                <p>Warsztaty zdrowia i natury</p>
              </label>
              <div class="tab-content">
                Na tych warsztatach zrobisz wyjątkowe, unikatowe i jedyne w swoim rodzaju produkty, Serdecznie zapraszamy na tworzenie! Chcesz poznać tajne, ludowe receptury, które do dziś przetrwały pod strzechą? Chcemy się nimi z Tobą podzielić, bo wierzymy że dobrze wiedzę wykorzystasz. Zapraszamy! Zapraszamy na warsztaty, w których obudzisz wszystkie zmysły. Będziesz mógł przede wszystkim zioła zobaczyć z bliska (rozpoznać w postaci suchych i świeżych części roślin), powąchać (poczuć ziołowe aromaty), dotknąć (poznać struktury, kształty, grubości poszczególnych części ziół), usłyszeć szelest surowców roślinnych i posmakować naparów, soków, syropów z ziół, a nade wszystko uaktywnić 6 zmysł tj. Tworzenie. Zapowiada się arcyzmysłowo!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd9" name="rd" />
              <label class="tab-label" for="rd9">
                <p>Warsztaty sporowe gry zespołowe</p>
              </label>
              <div class="tab-content">
                Warsztaty na obiektach sportowych, czyli na boiskach zlokalizowanych w pobliżu szkoły w Piaskach. Pogramy w piłkę nożną, siatkówkę i dwa ognie. Nie zapomnijcie stroju sportowego ;)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd10" name="rd" />
              <label class="tab-label" for="rd10">
                <p>Warsztaty filmowe</p>
              </label>
              <div class="tab-content">
                Jak to jest po drugiej stronie kamery? Sprawdzimy! Podczas tegorocznych warsztatów zarejestrujemy z naszych kamer, aparatów fotograficznych lub telefonów materiał filmowy z jednego dnia PARADISO, który następnie przejrzymy, zsynchronizujemy i zmontujemy. Przy okazji porozmawiamy o podstawowych zasadach realizacji zdjęć i montażu. Wykorzystamy urządzenia stabilizujące kamerę, a do montażu program Adobe Premiere CC. Zabierzcie koniecznie ze sobą sprzęt rejestrujący oraz statyw.
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd11" name="rd" />
              <label class="tab-label" for="rd11">
                <p>Warsztaty współczesne gry planszowe</p>
              </label>
              <div class="tab-content">
                Któż z nas nie uwielbia planszówek? Gry planszowe rozwijają podstawowe kompetencje interpersonalne, logicznne i myślowwe. W dobie komputeryzacji i komunikatorów, wspólne spędzanie czasu w gronie przyjaciół, rodzinnym w „realu” jest bardzo wskazane. Gry planszowe dają ogrom satysfakcji z samej rozgrywki, uczą wielu zagadnień matematycznych, logicznych, historycznych czy przyrodniczych. Przede wszystkim rozgrywka w gry planszowe to ogromna dawka dobrej zabawy i u nas ją znajdziesz!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd12" name="rd" />
              <label class="tab-label" for="rd12">
                <p>Warsztaty kulinarne</p>
              </label>
              <div class="tab-content">
                Z kuchni klasztornej po korytarzach klasztornych i wokół studni na starym wirydarzu unoszą się – jak anioły po Raju – PARADISO – piękne, kuszące i za żołądek chwytające zapachy: pieczenia, gotowania, smażenia… Staropolskie przysłowie „Przez żołądek do serca” w codziennym życiu sprawdza się w 100 procentach. Przyjdź, zobacz i posmakuj! Acha, no jeszcze ugotuj najpierw! :)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd13" name="rd" />
              <label class="tab-label" for="rd13">
                <p>Warsztaty lego</p>
              </label>
              <div class="tab-content">
                Klocki lego kojarzą nam się z beztroskim dzieciństwem, ale nic bardziej mylnego. Bez względu na to, czy ma się 7 czy 40 lat, trudno oprzeć się przyjemności układania klocków Lego. To zapewne powód, dla którego wiele osób wykorzystuje je w edukacji. Od przedszkola aż po szkoły wyższe klocki Lego służą nauce matematyki, fizyki, informatyki, inżynierii, a także interakcji społecznych. Szacuje się, że klockami Lego, każdego roku bawi się 400 mln ludzi na świecie. Na terenie powiatu gostyńskiego funkcjonuje Mobilne Centrum Kreatywności, które za sprawą Fundacji Fabryka Pomysłów zawita na PARASIO. Dla wielu przygoda z Lego rozpoczęła się w dzieciństwie od małego „gwiazdkowego zestawu”. Nie warto z Lego rezygnować o czym przekonają się uczestnicy zajęć.
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd14" name="rd" />
              <label class="tab-label" for="rd14">
                <p>Warsztaty florystyczne</p>
              </label>
              <div class="tab-content">
                Pięknie Pan Bóg nam stworzył ten świat. Na tych warsztatach odkryjemy piękno kompozycji kwiatowych na różne okazje, wykonamy wianki z kwiatów lub inne kompozycje oraz obudzimy w sobie kreatywność i zmysł tworzenia. Piękno kwiatów zachwyca każdego!
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd15" name="rd" />
              <label class="tab-label" for="rd15">
                <p>Warsztaty bębniarskie</p>
              </label>
              <div class="tab-content">
                Jeśli masz swój bęben to koniecznie zabierz ze sobą, bo czeka Cię interaktywna wycieczka dookoła świata, podczas której poznasz różne kultury. Jeśli nie masz swojego bębna i nie umiesz grać, to coś dla Ciebie znajdziemy i sprawimy, że nie zawahasz się na nim zagrać. Może uda nam się zbudować wielką orkiestrę bębniarską?
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd16" name="rd" />
              <label class="tab-label" for="rd16">
                <p>Warsztaty zmalujmy coś wielkiego</p>
              </label>
              <div class="tab-content">
                Chcemy naprawdę zmalować coś wielkiego na wielkim płótnie, więc jeśli potrafisz malować to zapisuj się na te warsztaty i pokaż co potrafisz :)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd17" name="rd" />
              <label class="tab-label" for="rd17">
                <p>Warsztaty fotograficzne</p>
              </label>
              <div class="tab-content">
                Fotografia bez stresu i na luzie! Popracujemy nad świadomym fotografowaniem i pełną świadomością artystyczną, a to wszystko z odpowiednią i doskonałą techniką. Jeśli masz aparat to koniecznie zabierz ze sobą.
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd18" name="rd" />
              <label class="tab-label" for="rd18">
                <p>Warsztaty rytm i muzyka</p>
              </label>
              <div class="tab-content">
                Rytm, harmonia, muzyka... Pośpiewamy, pogramy, potupiemy, poklaszczemy... Będzie fajnie, będzie dobra zabawa ;)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd19" name="rd" />
              <label class="tab-label" for="rd19">
                <p>Warsztaty hip-hop</p>
              </label>
              <div class="tab-content">
                Lubisz tańczyć? Te warsztaty są właśnie dla Ciebie i nie możesz ich przegapić. Bierz wygodne buty, butelkę wody i pobujaj się z nami w rytmie hip-hopu ;)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd20" name="rd" />
              <label class="tab-label" for="rd20">
                <p>Warsztaty pomaluj swój eko-karton</p>
              </label>
              <div class="tab-content">
                W dzisiejszych czasach trzeba być eko! To wspaniały i cudowny trend. Kartony są wszędzie, a my zrobimy z niego coś fajnego, kolorowego i zwariowanego. Niech wyobraźnia Was nie ogranicza ;)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd21" name="rd" />
              <label class="tab-label" for="rd21">
                <p>Warsztaty plastikowa rewolucja</p>
              </label>
              <div class="tab-content">
                Na tych warsztatach czeka nas rewolucja i przedmiotom już nieużytecznym nadamy nowe życie oraz nowe zastosowanie. Czy powstaną z nich wielkie dzieła? Hm... ;)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd22" name="rd" />
              <label class="tab-label" for="rd22">
                <p>Warsztaty eko-puzzle dla inteligentnych</p>
              </label>
              <div class="tab-content">
                Oj czasami trzeba nieźle pogłówkować, a ten warsztat będzie jedną wielką łamigłówką. I znów będziemy eko i zrobimy coś dobrego dla środowiska.
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd23" name="rd" />
              <label class="tab-label" for="rd23">
                <p>Warsztaty escape klasztor</p>
              </label>
              <div class="tab-content">
                Tajemniczy pokój gdzieś w klasztorze... Ratuj się kto może! I odkrywaj tajemnice barokowego obiektu. Komu się uda...? ;)
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd24" name="rd" />
              <label class="tab-label" for="rd24">
                <p>Warsztaty śpiewu tradycyjnego</p>
              </label>
              <div class="tab-content">
                Dowiemy się, czym jest śpiew tradycyjny i tzw. Otwarty, biały, etniczny głos, głos, który wychodzi z głębi, uwalnia nas i pozwala sięgnąć do naszych korzeni. Poznamy jak to się kiedyś śpiewało, a także poczujemy to dzięki śpiewaniu tradycyjnych pieśni ludowych.
              </div>
            </div>

            <div class="tab">
              <input type="radio" id="rd25" name="rd" />
              <label class="tab-label" for="rd25">
                <p>Warsztaty emisji głosu</p>
              </label>
              <div class="tab-content">
                Śpiewać każdy może.... Naprawdę! Nie ma ludzi bez słuchu i wszystko można wypracować. Jeśli lubisz śpiewać to są warsztaty dla Ciebie. Pooddychamy, pośpiewamy, a to wszystko z ogromną świadomością.
              </div>
            </div>


          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
  export default {
    components: {},
  };
</script>
<style lang="scss" scoped>
  /* Accordion styles */
  $midnight: #2c3e50;
  $clouds: #ecf0f1;
  .tabs {
    // border-radius: 8px;
    overflow: hidden;
    // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      /* background: #2c3e50; */
      font-weight: bold;
      cursor: pointer;
      color: #000;
      border-bottom: 1px solid $red;
      p {
        transition: all 0.35s;
      }
      /* Icon */
      &:hover {
        p {
          transform: translateX(2%);
        }
        // background: darken($midnight, 10%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: $midnight;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $midnight;
      cursor: pointer;
      &:hover {
        background: darken($midnight, 10%);
      }
    }
  }

  // :checked
  input:checked {
    + .tab-label {
      // background: darken($midnight, 10%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
    }
  }
</style>
